import React, { FC, useState, useEffect } from 'react';

import cn from 'classnames';

import Image from 'components/atoms/Image/Image';

type ProductSliderHighlightsProps = {
  index: number;
  highlights: string[];
  onClick: (index: number) => void;
};

const ProductSliderHighlights: FC<ProductSliderHighlightsProps> = ({ index, highlights, onClick }) => {
  const [indexToUpdate, setIndexToUpdate] = useState(index);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  useEffect(() => {
    setIndexToUpdate(index);
  }, [index]);

  return <div>
    <ul className="product__highlight my-l">
      {highlights.map((highlight, i) => (
        <li className="flex cursor-pointer" key={`product-hightligh-${i}`} onMouseEnter={() => setHoveredIndex(i)} onMouseLeave={() => setHoveredIndex(-1)} onClick={() => onClick(i)}>
          <Image name="check-mark-purple-round" className={cn('inline-block min-w-[16px] h-s mr-[5px]', { 'hidden': hoveredIndex !== i || indexToUpdate === i })} />
          <Image name="check-mark-grey-round" className={cn('inline-block min-w-[16px] h-s mr-[5px]', { 'hidden': indexToUpdate === i || hoveredIndex === i })} />
          <Image name="check-mark-black-round" className={cn('inline-block min-w-[16px] h-s mr-[5px]', { 'hidden': indexToUpdate !== i })} />
          <div className={cn(
            'max-w-[570px] mb-s text-base-600',
            { 'text-black font-[500]': indexToUpdate === i }, { 'hover:text-blue-1000': indexToUpdate !== i }
          )}>
            {highlight}
          </div>
        </li>
      ))}
    </ul>
  </div>;
};

export default ProductSliderHighlights;