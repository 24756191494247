export const fractalHighlights = [
  'Enhanced Governance, Visibility, and Automation',
  'Support SaaS, PaaS, and IaaS services on all Cloud Vendors',
  'Simplify any Kubernetes Distro in a public, private, or hybrid setups',
  'Integrate your existing IaC solutions as custom components',
  'Flexibility and Extendibility thanks to Open Standards',
  'Automated scanning and onboarding of existing infrastructure setups',
  'RBAC and Granular Control',
];

// List of images to pass to slider in the same order as the above highlights
// (now there is only one image to be replaced by new designed ones)
export const fractalImages = [
  "home-page/fractal-product",
  "home-page/fractal-product",
  "home-page/fractal-product",
  "home-page/fractal-product",
  "home-page/fractal-product",
  "home-page/fractal-product",
  "home-page/fractal-product",
];